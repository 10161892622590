exports.components = {
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-code-of-conduct-tsx": () => import("./../../../src/pages/code-of-conduct.tsx" /* webpackChunkName: "component---src-pages-code-of-conduct-tsx" */),
  "component---src-pages-da-page-generic-slug-tsx": () => import("./../../../src/pages/{DAPageGeneric.slug}.tsx" /* webpackChunkName: "component---src-pages-da-page-generic-slug-tsx" */),
  "component---src-pages-donate-da-fundraiser-name-tsx": () => import("./../../../src/pages/donate/{DAFundraiser.name}.tsx" /* webpackChunkName: "component---src-pages-donate-da-fundraiser-name-tsx" */),
  "component---src-pages-donate-index-tsx": () => import("./../../../src/pages/donate/index.tsx" /* webpackChunkName: "component---src-pages-donate-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-needs-assessments-explorer-tsx": () => import("./../../../src/pages/needs-assessments/explorer.tsx" /* webpackChunkName: "component---src-pages-needs-assessments-explorer-tsx" */),
  "component---src-pages-regions-da-region-slug-tsx": () => import("./../../../src/pages/regions/{DARegion.slug}.tsx" /* webpackChunkName: "component---src-pages-regions-da-region-slug-tsx" */),
  "component---src-pages-regions-da-subregion-region-slug-da-subregion-slug-tsx": () => import("./../../../src/pages/regions/{DASubregion.region__slug}/{DASubregion.slug}.tsx" /* webpackChunkName: "component---src-pages-regions-da-subregion-region-slug-da-subregion-slug-tsx" */),
  "component---src-pages-regions-index-tsx": () => import("./../../../src/pages/regions/index.tsx" /* webpackChunkName: "component---src-pages-regions-index-tsx" */),
  "component---src-pages-routes-da-route-slug-tsx": () => import("./../../../src/pages/routes/{DARoute.slug}.tsx" /* webpackChunkName: "component---src-pages-routes-da-route-slug-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-vis-tsx": () => import("./../../../src/pages/vis.tsx" /* webpackChunkName: "component---src-pages-vis-tsx" */),
  "component---src-pages-whistleblowing-policy-tsx": () => import("./../../../src/pages/whistleblowing-policy.tsx" /* webpackChunkName: "component---src-pages-whistleblowing-policy-tsx" */)
}

